import { format } from 'date-fns';

// eslint-disable-next-line import/prefer-default-export
export const formatDate = (timestamp?: string | null, dateFormat?: string, locale?: Locale) => {
  try {
    if (timestamp) {
      const date = new Date(timestamp);
      if (date) return format(date, dateFormat || 'Pp', { locale });
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('[Pump Information Utils] Format Date Error', timestamp, locale?.code, error);
  }
};

// Converting Geolocation latitude and longitude dms(Degrees Minutes Seconds) to decimal.
// Example :- "56°22'34.8"N 9°39'55.0"E" to 56.376339, 9.665266
const dmsRegix = /^(\d{1,2})°(\d{1,2})'(\d{1,2}\.\d+)"([NS]) (\d{1,2})°(\d{1,2})'(\d{1,2}\.\d+)"([EW])$/;

function dmsToDecimal(dms: string) {
  const match = dms.match(/(\d{1,2})°(\d{1,2})'(\d{1,2}\.\d+)"([NSEW])/);
  if (!match) return;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, d, m, s, dir] = match;
  const decimal = parseInt(d, 10) + parseInt(m, 10) / 60 + parseFloat(s) / 3600;
  return (dir === 'S' || dir === 'W' ? -decimal : decimal).toFixed(7);
}

export function convertGeolocationValue(value: string): string {
  if (!value?.match(dmsRegix)) {
    return value
      .split(',')
      .map((e) => Number(e).toFixed(7))
      .join(',');
  }
  const [latDMS, lonDMS] = value.split(' ');
  const lat = dmsToDecimal(latDMS);
  const lon = dmsToDecimal(lonDMS);
  return `${Number(lat).toFixed(7)},${Number(lon).toFixed(7)}`;
}
