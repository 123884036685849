import { ProportionalRemedy, ProportionalRule } from '../types';

const getRemedyTranslateKey = (remedy: ProportionalRemedy): string => {
  switch (remedy) {
    case 'RESET_REMOTE':
      return 'WP.ANALOG_PROPORTIONAL_MESSAGE_RESET_REMOTE';
    case 'RESET_ON_SITE':
      return 'WP.ANALOG_PROPORTIONAL_MESSAGE_RESET_ON_SITE';
    default:
      throw new Error(`Remedy '${remedy}' not found.`);
  }
};

const getRuleTranslateKey = (rule: ProportionalRule): string => {
  switch (rule) {
    case 'ZeroToTwentyLowerInputCurrent':
      return 'WP.ANALOG_PROPORTIONAL_DDA_LOWER_ELECTRIC_CURRENT_VIOLATION_0_20';
    case 'FourToTwentyLowerInputCurrent':
      return 'WP.ANALOG_PROPORTIONAL_DDA_LOWER_ELECTRIC_CURRENT_VIOLATION_4_20';
    case 'LowerInputCurrentFlow':
      return 'WP.ANALOG_PROPORTIONAL_DDA_LOWER_FLOW_VIOLATION';
    case 'UpperInputCurrent':
      return 'WP.ANALOG_PROPORTIONAL_DDA_HIGHER_ELECTRIC_CURRENT_VIOLATION';
    case 'AnalogOperatingModeFlowSensorMismatch420':
      // fix:SDCS-3266 investigated and found that below key was removed and same value was there in different key so changing it new one.
      // return 'WP.REMOTE.VALIDATION.EXTERNAL_FLOW_SENSOR_0_20';
      return 'WP.REMOTE.ANALOG_PROPORTIONAL_EXTERNAL_FLOW_SENSOR_0_20';
    case 'AnalogOperatingModeFlowSensorMismatch020':
      // fix:SDCS-3266 investigated and found that below key was removed and same value was there in different key so changing it new one.
      // return 'WP.REMOTE.VALIDATION.EXTERNAL_FLOW_SENSOR_4_20';
      return 'WP.REMOTE.ANALOG_PROPORTIONAL_EXTERNAL_FLOW_SENSOR_4_20';
    default:
      throw new Error(`Rule '${rule}' not found.`);
  }
};

/**
 * Provided an existing set of rule violations; return all of those violations that matches macthRuleViolations.
 */
export const hasRuleViolation = (
  violations: readonly ProportionalRule[] | undefined | null,
  ...macthRuleViolations: ProportionalRule[]
): ProportionalRule[] => (violations || []).filter((rule) => macthRuleViolations.includes(rule));

/**
 * Turns a list of ruleViolations and remedy into a translated text. Each of the rules and the remedy is seperated by a line break.
 */
export const formatRuleViolationText = (
  remedy: ProportionalRemedy | undefined | null,
  ruleViolations: ProportionalRule[] | undefined | null,
  translate: (value: string) => string,
): string => {
  const stringBuilder = (ruleViolations || []).map((ruleViolation) => translate(getRuleTranslateKey(ruleViolation)));
  // The remedy text is only relevant if one or more rules has been violated
  if (remedy !== null && remedy !== undefined && stringBuilder.length > 0)
    stringBuilder.push(translate(getRemedyTranslateKey(remedy)));
  // We join each rule and remete with a new line.
  return stringBuilder.join('\n');
};
