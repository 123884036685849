import { Formik, Form } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { TFunction, useTranslation } from 'react-i18next';
import { graphql, useLazyLoadQuery, useMutation } from 'relay-hooks';
import { useParams } from 'react-router';
import { toGlobalId } from 'graphql-relay';
import { mainContentContainerStyles } from './styles';
import { EditInformationForm, EditInformationProps, SnackbarProps } from './types';
import { Loader, NoDataAvailableMessage, Snackbar } from '../../../../../components';
import { EditInformationQuery } from './__generated__/EditInformationQuery.graphql';
import { EditInformationMutation } from './__generated__/EditInformationMutation.graphql';
import { EditInformationGeolocationMutation } from './__generated__/EditInformationGeolocationMutation.graphql';
import { convertGeolocationValue } from './utils';

const validationSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string(),
    phone: Yup.string(),
    geolocation: Yup.string()
      .nullable()
      .notRequired()
      .matches(
        /^([-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?))|((\d{1,2})°(\d{1,2})'(\d{1,2}\.\d+)"([NS]) (\d{1,2})°(\d{1,2})'(\d{1,2}\.\d+)"([EW]))$/,
        t('WP.OVERVIEW.MODAL.GEO_LOCATION.VALIDATION.FORMAT_NOT_SUPPORTED'),
      ),
  });

const EditInformation: React.FC<EditInformationProps> = ({ formId, loadingCallback, children }) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState<SnackbarProps>();
  const data = useLazyLoadQuery<EditInformationQuery>(
    graphql`
      query EditInformationQuery($id: ID!) {
        node(id: $id) {
          ... on Device {
            gatewayIMEI
            location {
              ... on Installation {
                id
                geoLocation
              }
            }
            deviceInfo {
              ... on Gateway {
                contactPerson {
                  contactPersonName
                  contactPersonPhone
                }
              }
            }
          }
        }
      }
    `,
    {
      id: toGlobalId('Device', id || ''),
    },
  );

  const [commit, contactMutation] = useMutation<EditInformationMutation>(graphql`
    mutation EditInformationMutation($input: UpdateContactPersonInput) {
      updateContactPerson(input: $input) {
        id
        contactPerson {
          contactPersonName
          contactPersonPhone
        }
      }
    }
  `);

  const [commitGeolocation, geolocationMutation] = useMutation<EditInformationGeolocationMutation>(graphql`
    mutation EditInformationGeolocationMutation($input: UpdateGeolocationInput) {
      updateGeolocation(input: $input) {
        id
        latitude
        longitude
      }
    }
  `);

  if (!data.data?.node) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;
  const { deviceInfo, location, gatewayIMEI } = data.data.node;

  const { id: hirarichieId, geoLocation: url } = location || {};
  const geolocation = new URLSearchParams(url || '').get('query');
  const { contactPersonName: name, contactPersonPhone: phone } = deviceInfo?.contactPerson || {};

  const closeSnackbarAfterDelay = () => setTimeout(() => setShowSnackbar(false), 3000);

  const initialValues: EditInformationForm = {
    name,
    phone,
    geolocation,
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema(t)}
      onSubmit={(values) => {
        const geolocationValue = convertGeolocationValue(values.geolocation || '');
        if (values.geolocation !== geolocation || (!values.geolocation && geolocation)) {
          const [latitude, longitude] = geolocationValue.split(',') || ['', ''];
          commitGeolocation({
            variables: {
              input: {
                id: hirarichieId || '',
                gatewayId: gatewayIMEI || '',
                latitude,
                longitude,
              },
            },
            onError: (error: any) => {
              loadingCallback(false);
              // eslint-disable-next-line no-console
              console.error(error);
              setSnackbarProps({ snackbarType: 'WARNING', message: t('WP.CONTACT_PERSON_SAVE_FAILED') });
              setShowSnackbar(true);
            },
          });
        }
        if (id) {
          loadingCallback(true);
          commit({
            variables: {
              input: {
                deviceId: id,
                name: values.name,
                phone: values.phone,
              },
            },
            onCompleted: () => {
              loadingCallback(false);
              // eslint-disable-next-line no-console
              setSnackbarProps({ snackbarType: 'SUCCESS', message: t('WP.PUMPINFORMATION_SAVE_SUCCESS') });

              setShowSnackbar(true);
              closeSnackbarAfterDelay();
              if (data) data.retry();
            },
            onError: (error: any) => {
              loadingCallback(false);
              // eslint-disable-next-line no-console
              console.error(error);
              setSnackbarProps({ snackbarType: 'WARNING', message: t('WP.CONTACT_PERSON_SAVE_FAILED') });
              setShowSnackbar(true);
            },
          });
        }
      }}
    >
      <Form id={formId} style={{ position: 'relative' }} css={mainContentContainerStyles}>
        {showSnackbar && (
          <Snackbar
            snackbarType={snackbarProps?.snackbarType}
            message={snackbarProps?.message || ''}
            onClose={() => setShowSnackbar(false)}
          />
        )}
        {(contactMutation.loading || geolocationMutation.loading) && (
          <div style={{ position: 'absolute', backdropFilter: 'blur(0.5px)', width: '100%', height: '100%' }}>
            <Loader />
          </div>
        )}
        {children}
      </Form>
    </Formik>
  );
};

export default EditInformation;
