import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { toGlobalId } from 'graphql-relay';
import { heading05Styles, labelStyles, paragraphBoldStyles } from '../../../../styles';
import { Tile, TileHeader, TileBody, InfoItem, DateTimeFormatter } from '../../../../components';
import {
  containerStyles,
  twoColumsTileStyles,
  infoItemStyles,
  leftColumnStyles,
  removeLastBottomBorder,
  rightColumnStyles,
  topPadding,
  removeBorder,
  dataBoxStyles,
  dataBoxTextContainerStyles,
  tileStyles,
} from './styles';
import { MoreDataQuery } from './__generated__/MoreDataQuery.graphql';
import { GeniIdentity } from '../../types';
import { formatPulseRateValue, convertToSuperScript, flowMeterLabel } from '../../utils';
import dataPointsGrouping from '../../Overview/OverviewChemPairing/DosedVolume/dataPointsGrouping';

const DataBox = ({ label, value, unit }: { label: string; value?: number | string | null; unit?: string | null }) => (
  <div css={topPadding}>
    <div css={dataBoxStyles}>
      <p css={labelStyles}>{label}</p>
      <div css={[heading05Styles, dataBoxTextContainerStyles]}>
        <span>{value}</span>
        <span>{unit}</span>
      </div>
    </div>
  </div>
);

const PulseData = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { t } = useTranslation();
  return (
    <Tile
      styles={tileStyles}
      Header={<TileHeader text={t('WP.MORE.DATA.VOLUMETRIC_CONCENTRATION_TILE')} />}
      Body={
        <TileBody css={[topPadding]}>
          <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.PULSE_OPERTAION_MODE')}</p>
          <div css={twoColumsTileStyles}>{children}</div>
        </TileBody>
      }
    />
  );
};

const ManualData = ({ children }: { children: JSX.Element[] | JSX.Element }) => {
  const { t } = useTranslation();
  return (
    <Tile
      Header={<TileHeader text={t('WP.MORE.DATA.DOSED_VOL_TILE')} />}
      Body={
        <TileBody css={topPadding}>
          <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.MANUAL_OPERATION_MODE')}</p>
          <div css={twoColumsTileStyles}>{children}</div>
        </TileBody>
      }
    />
  );
};

const MoreData = ({
  endDate,
  startDate,
  geniIdentity,
}: {
  endDate: number;
  startDate: number;
  geniIdentity: GeniIdentity;
}) => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();

  const data = useLazyLoadQuery<MoreDataQuery>(
    graphql`
      query MoreDataQuery($id: ID!, $endDate: Int!, $startDate: Int!, $geniIdentity: GeniIdentity!) {
        node(id: $id) {
          ... on Device {
            descendantDeviceInfo {
              ... on DDA {
                pumpSettings {
                  pulseRateSettings {
                    meterType
                    pulseRate {
                      quantity
                      unit
                    }
                  }
                }
              }
            }
            moreDataAppSettings: appSettings(
              settingSelector: [
                { name: "sdcs:VOLUME_TOTAL_CHEM_REGISTRATION" }
                { name: "sdcs:CHEMICAL_NAME" }
                { name: "sdcs:VOLUME_CHEMICAL_WARNING_TRESHOLD" }
                { name: "sdcs:CHEMICAL_CONNECTED" }
              ]
              endDate: $endDate
            ) {
              updatedAt
              updatedBy
              name
              value
              unit
            }
            moreDataLatestDeviceData: latestDeviceData {
              dataPoints {
                name
                unit
                value
              }
            }
            moreDataTimeSeriesDatapoints: timeSeriesDatapoints(
              datapointSelectors: [
                { name: "sdcs:CALCULATED_CONTAINER_LEVEL", raw: true, geniIdentity: $geniIdentity }
                { name: "sdcs:DOSED_VOLUME_HOURLY", raw: true, geniIdentity: $geniIdentity }
                { name: "sdcs:DOSED_VOLUME_HOURLY_START", raw: true, geniIdentity: $geniIdentity }
                { name: "sdcs:PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION", raw: true, geniIdentity: $geniIdentity }
                { name: "gfdm:RUNTIME", raw: true, geniIdentity: $geniIdentity }
                { name: "gfdm:VOLUME", raw: true, geniIdentity: $geniIdentity }
                { name: "sdcs:VOLUME_PROCESSED", raw: true, geniIdentity: $geniIdentity }
              ]
              startDate: $startDate
              endDate: $endDate
            ) {
              name
              unit
              latestDatapoint {
                valueRaw
              }
              datapoints {
                createdAt
                valueRaw
              }
            }
          }
        }
      }
    `,
    {
      id: toGlobalId('Device', id || ''),
      endDate,
      startDate,
      geniIdentity,
    },
    { fetchPolicy: 'store-and-network' },
  );

  const controlMode = data.node?.moreDataLatestDeviceData?.dataPoints?.find((dp) => dp?.name === 'gfdm:CONTROL_MODE');

  // Chemical info tile
  const connectedChemical = data.node?.moreDataAppSettings?.find((item) => item?.name === 'sdcs:CHEMICAL_NAME');
  const connectedBy = data.node?.moreDataAppSettings?.find((item) => item?.name === 'sdcs:CHEMICAL_NAME')?.updatedBy;
  const connectedVolume = data.node?.moreDataAppSettings?.find(
    (item) => item?.name === 'sdcs:VOLUME_TOTAL_CHEM_REGISTRATION',
  );
  const connectionDate = connectedVolume?.updatedAt;
  // actual volume
  const calculateLevelDatapoint = data?.node?.moreDataTimeSeriesDatapoints?.find(
    (item) => item.name === 'CALCULATED_CONTAINER_LEVEL',
  );
  const calulatedLevel = calculateLevelDatapoint?.latestDatapoint.valueRaw ?? Number(connectedVolume?.value);
  const calulatedLevelUnit = calculateLevelDatapoint?.unit ?? connectedVolume?.unit;
  const calculatedPreEmptyThreshold = data.node?.moreDataAppSettings?.find(
    (item) => item?.name === 'sdcs:VOLUME_CHEMICAL_WARNING_TRESHOLD',
  );

  // Dosing counter tile
  const totalVolumeDosedChemical = data.node?.moreDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:VOLUME',
  );
  const totalVolumeProcessLiquid = data.node?.moreDataTimeSeriesDatapoints?.find(
    (dp) => dp.name === 'VOLUME_PROCESSED',
  );

  const motorOperating = data.node?.moreDataTimeSeriesDatapoints?.find((dp) => dp.name === 'RUNTIME');
  const operatingHours = data.node?.moreDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:TOTAL_POWERED_TIME',
  );

  // Volumentric concentration tile (PULSE)
  const volmetricLastHour = data?.node?.moreDataTimeSeriesDatapoints?.find(
    (item) => item.name === 'PROPORTIONAL_DOSING_ACTUAL_CONCENTRATION',
  );
  // targetVolume is a calculated value which is why we don't fetch it directly from settings service
  const targetVolume =
    data?.node?.moreDataLatestDeviceData?.dataPoints?.find((dp) => dp?.name === 'sdcs:PROPORTIONAL_DOSING_TARGET')
      ?.value || 0;
  const targetVolUnit = convertToSuperScript(
    data?.node?.moreDataLatestDeviceData?.dataPoints?.find((dp) => dp?.name === 'sdcs:PROPORTIONAL_DOSING_TARGET')
      ?.unit || '',
  );

  const dosedVolLastHour = data.node?.moreDataTimeSeriesDatapoints?.find((dp) => dp.name === 'DOSED_VOLUME_HOURLY');
  const dosedVolLastHourStart = data.node?.moreDataTimeSeriesDatapoints?.find(
    (dp) => dp.name === 'DOSED_VOLUME_HOURLY_START',
  );

  const dosedProcessVolLastHour = data.node?.moreDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'sdcs:VOLUME_PROCESSED_HOURLY',
  );

  const { meterType, pulseRate } = data?.node?.descendantDeviceInfo?.pumpSettings?.pulseRateSettings || {};
  const ddaVolPerPulse = data.node?.moreDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:PULSE_DOSING_VOLUME',
  );

  // Dosed volume tile (MANUAL)
  // We use the dataPointsGrouping here to align with the way we do on the manual dosing part
  // In future we should get the data needed from our graphql instead.
  // We are overfetching here and sometimes this data will not be relevant (e.g. for prorppostional dosing)
  const { volumeLastHour } = dataPointsGrouping(
    dosedVolLastHour?.datapoints || [],
    dosedVolLastHourStart?.datapoints || [],
    // We only need data from the last hour
    1,
  );

  const selectedDosingVol = data.node?.moreDataLatestDeviceData?.dataPoints?.find(
    (dp) => dp?.name === 'gfdm:MANUAL_DOSING_CAPACITY',
  );

  return (
    <div css={containerStyles}>
      <div css={leftColumnStyles}>
        {controlMode?.value === 1 && (
          <PulseData>
            <div>
              <DataBox
                // We use the same translation key here as on the tile. We have seen issues where it is important that they align
                label={t('WP.VOLUMETRIC.CONCENTRATION.TILE_LAST_HOUR')}
                value={volmetricLastHour?.latestDatapoint.valueRaw?.toFixed(1)}
                unit={convertToSuperScript(volmetricLastHour?.unit || '')}
              />
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA.PULSE_DOSED_VOL_LAST_HOUR')}
                value={dosedVolLastHour?.latestDatapoint.valueRaw?.toFixed(4)}
                unit={dosedVolLastHour?.unit}
              />
              <InfoItem
                css={[infoItemStyles, removeBorder]}
                label={t('WP.MORE.DATA.PULSE_DOSED_VOLUME_LAST_HOUR')}
                value={dosedProcessVolLastHour?.value?.toFixed(0)}
                unit={dosedProcessVolLastHour?.unit}
              />
            </div>
            <div>
              <DataBox label={t('WP.MORE.DATA.PULSE_TARGET')} value={targetVolume.toFixed(1)} unit={targetVolUnit} />
              <InfoItem
                css={infoItemStyles}
                label={t(
                  flowMeterLabel(
                    'WP.MORE.DATA.FLOW_TURBINE',
                    'WP.MORE.DATA.WATER_METER',
                    'WP.MORE.DATA.FLOW_METER_PULSE_RATE',
                    meterType,
                  ),
                )}
                value={(pulseRate?.quantity && formatPulseRateValue(Number(pulseRate?.quantity), meterType)) || '-'}
                unit={pulseRate?.quantity ? pulseRate?.unit : null}
              />
              <InfoItem
                css={[infoItemStyles, removeBorder]}
                label={t('WP.MORE.DATA.DDA_VOL_PER_PULSE')}
                value={ddaVolPerPulse?.value || '-'}
                unit={ddaVolPerPulse?.value ? t('WP.COMMON.ML_PER_PULSE') : null}
              />
            </div>
          </PulseData>
        )}
        {controlMode?.value === 0 && (
          <ManualData>
            <DataBox
              label={t('WP.MORE.DATA.MANUAL_DOSED_VOL_LAST_HOUR')}
              value={volumeLastHour?.toFixed(4)}
              unit={dosedVolLastHour?.unit}
            />
            <DataBox
              label={t('WP.MORE.DATA.MANUAL_SELECTED_DOSING_VOL')}
              value={selectedDosingVol?.value?.toFixed(4)}
              unit={selectedDosingVol?.unit}
            />
          </ManualData>
        )}

        <Tile
          Header={<TileHeader text={t('WP.MORE.DATA.CHEMICAL_INFO_TILE')} />}
          Body={
            <TileBody css={[topPadding, removeLastBottomBorder]}>
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA.CONNECTED_CHEMICAL')}
                value={connectedChemical?.value}
              />
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA.CHEMICAL_CONNECTION_DATE')}
                value={connectionDate && <DateTimeFormatter timestamp={connectionDate} />}
              />
              <InfoItem css={infoItemStyles} label={t('WP.MORE.DATA.CONNECTED_BY')} value={connectedBy} />
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA.CONNECTED_VOL')}
                value={connectedVolume?.value ? `${Number(connectedVolume?.value).toFixed(1)}` : 0}
                unit={connectedVolume?.value ? connectedVolume?.unit : null}
              />
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA.ACTUAL_VOL')}
                // we don't want to display negative values
                value={calulatedLevel && calulatedLevel > 0 ? `${calulatedLevel.toFixed(1)}` : 0}
                unit={calulatedLevel ? calulatedLevelUnit : null}
              />
              <InfoItem
                css={infoItemStyles}
                label={t('WP.MORE.DATA.PRE_EMPTY_TRESHOLD')}
                value={
                  calculatedPreEmptyThreshold?.value ? `${Number(calculatedPreEmptyThreshold?.value).toFixed(1)}` : ''
                }
                unit={calculatedPreEmptyThreshold?.value ? calculatedPreEmptyThreshold?.unit : null}
              />
            </TileBody>
          }
        />
      </div>
      <div css={rightColumnStyles}>
        <Tile
          styles={tileStyles}
          Header={<TileHeader text={t('WP.MORE.DATA.DOSING_COUNTER_TILE')} />}
          Body={
            <TileBody css={[topPadding, twoColumsTileStyles]}>
              <div css={removeLastBottomBorder}>
                <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.VOLUME')}</p>
                <InfoItem
                  css={infoItemStyles}
                  label={t('WP.MORE.DATA.TOTAL_DOSED_CHEMICAL')}
                  // For now we don't show any decimals
                  value={totalVolumeDosedChemical?.value?.toFixed(0)}
                  unit={totalVolumeDosedChemical?.unit}
                />
                {/* We should only display Total volume process liquid in pulse mode */}
                {controlMode?.value === 1 && (
                  <InfoItem
                    css={infoItemStyles}
                    label={t('WP.MORE.DATA.TOTAL_PROCESS_LIQUID')}
                    // For now we don't show any decimals
                    value={totalVolumeProcessLiquid?.latestDatapoint.valueRaw?.toFixed(0)}
                    unit={totalVolumeProcessLiquid?.unit}
                  />
                )}
              </div>
              <div css={removeLastBottomBorder}>
                <p css={paragraphBoldStyles}>{t('WP.MORE.DATA.OPERATION')}</p>
                {/* doesn't show up */}
                <InfoItem
                  css={infoItemStyles}
                  label={t('WP.MORE.DATA.MOTOR_OPERATING')}
                  value={
                    motorOperating?.latestDatapoint.valueRaw && motorOperating?.latestDatapoint.valueRaw > 59
                      ? // We have asserted that we both have a value and that value is a number (implicit by gtr than) and than number is greater than 0
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        motorOperating?.latestDatapoint.valueRaw / 60
                      : '0'
                  }
                  unit={!motorOperating?.unit ? 'hours' : motorOperating?.unit}
                />
                <InfoItem
                  css={infoItemStyles}
                  label={t('WP.MORE.DATA.OPERATING_HOURS')}
                  value={operatingHours?.value}
                  unit={operatingHours?.unit}
                />
              </div>
            </TileBody>
          }
        />
      </div>
    </div>
  );
};

export default MoreData;
