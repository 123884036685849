/**
 * @generated SignedSource<<82bede2a649eca8440612e1c920562c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateGeolocationInput = {
  longitude?: string | null;
  latitude?: string | null;
  gatewayId: string;
  id: string;
};
export type EditInformationGeolocationMutation$variables = {
  input?: UpdateGeolocationInput | null;
};
export type EditInformationGeolocationMutation$data = {
  readonly updateGeolocation: {
    readonly id: string;
    readonly latitude: string | null;
    readonly longitude: string | null;
  } | null;
};
export type EditInformationGeolocationMutation = {
  variables: EditInformationGeolocationMutation$variables;
  response: EditInformationGeolocationMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateGeolocationPayload",
    "kind": "LinkedField",
    "name": "updateGeolocation",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "latitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longitude",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditInformationGeolocationMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditInformationGeolocationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "703d653bb68c32bc0dceebdecfd75522",
    "id": null,
    "metadata": {},
    "name": "EditInformationGeolocationMutation",
    "operationKind": "mutation",
    "text": "mutation EditInformationGeolocationMutation(\n  $input: UpdateGeolocationInput\n) {\n  updateGeolocation(input: $input) {\n    id\n    latitude\n    longitude\n  }\n}\n"
  }
};
})();

(node as any).hash = "e959be0485984620db46e5e0b5c7b22f";

export default node;
