import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, useFragment } from 'relay-hooks';
import { NoDataAvailableMessage } from '../../../components';
import InfoContainer from '../Overview/OverviewChemPairing/InstallationInfo/InfoContainer';
import { InfoContainerDDACDataProps } from './types';
// eslint-disable-next-line camelcase
import { InfoContainerDDACData_device$key } from './__generated__/InfoContainerDDACData_device.graphql';

const InfoContainerDDACData: React.FC<InfoContainerDDACDataProps> = ({ onClose, device }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const data = useFragment<InfoContainerDDACData_device$key>(
    graphql`
      fragment InfoContainerDDACData_device on Device {
        ...useInfoContainerData_device
        descendantDeviceInfo {
          ... on DDAC {
            productInfo {
              typeKey
              productNumber
              firmware
              serialNumber
            }
            pumpNameData {
              pumpName
            }
          }
        }
      }
    `,
    device.node,
  );

  if (!data) return <NoDataAvailableMessage message={t('WP.ERROR.NO_DATA')} />;
  const { productInfo, pumpNameData } = data.descendantDeviceInfo ?? {};
  const { typeKey, productNumber, firmware, serialNumber } = productInfo ?? {};
  const { pumpName } = pumpNameData ?? {};
  return (
    <InfoContainer
      productInfo={{
        typeKey: typeKey || '',
        productNumber: productNumber || '',
        serialNumber: serialNumber || '',
        firmware: firmware || '',
        pumpName: pumpName || undefined,
      }}
      device={device}
      onClose={onClose}
    />
  );
};

export default InfoContainerDDACData;
