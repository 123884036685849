/**
 * @generated SignedSource<<20620a633a169aef2a279cf8cc560b10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CalculatedContainerLevel_device$data = {
  readonly latestSync: {
    readonly outOfSync: boolean | null;
  } | null;
  readonly calcContainerAppSettings: ReadonlyArray<{
    readonly updatedAt: number | null;
    readonly name: string | null;
    readonly value: string | null;
    readonly unit: string | null;
  } | null> | null;
  readonly descendantDeviceInfo: {
    readonly chemicalContainer?: {
      readonly connectedChemical: string | null;
      readonly connectedTotalVolume: {
        readonly quantity: number | null;
        readonly unit: string | null;
      } | null;
      readonly chemicalConnectionDate: number | null;
      readonly thresholdValueForCalculatedPremptyAlert: {
        readonly quantity: number | null;
        readonly unit: string | null;
      } | null;
    } | null;
  } | null;
  readonly calcContainerTimeSeriesDatapoints: ReadonlyArray<{
    readonly name: string;
    readonly unit: string | null;
    readonly latestDatapoint: {
      readonly valueRaw: number | null;
    };
  }>;
  readonly " $fragmentType": "CalculatedContainerLevel_device";
};
export type CalculatedContainerLevel_device$key = {
  readonly " $data"?: CalculatedContainerLevel_device$data;
  readonly " $fragmentSpreads": FragmentRefs<"CalculatedContainerLevel_device">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "endDate",
  "variableName": "endDate"
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "quantity",
    "storageKey": null
  },
  (v2/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "endDate"
    },
    {
      "kind": "RootArgument",
      "name": "geniIdentity"
    },
    {
      "kind": "RootArgument",
      "name": "startDate"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "CalculatedContainerLevel_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "LatestSync",
      "kind": "LinkedField",
      "name": "latestSync",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "outOfSync",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "calcContainerAppSettings",
      "args": [
        (v0/*: any*/),
        {
          "kind": "Literal",
          "name": "settingSelector",
          "value": [
            {
              "name": "sdcs:VOLUME_TOTAL_CHEM_REGISTRATION"
            },
            {
              "name": "sdcs:CHEMICAL_NAME"
            },
            {
              "name": "sdcs:VOLUME_CHEMICAL_WARNING_TRESHOLD"
            }
          ]
        }
      ],
      "concreteType": "AppSetting",
      "kind": "LinkedField",
      "name": "appSettings",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        (v2/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "descendantDeviceInfo",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChemicalContainer",
              "kind": "LinkedField",
              "name": "chemicalContainer",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "connectedChemical",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "VolumeQuantity",
                  "kind": "LinkedField",
                  "name": "connectedTotalVolume",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "chemicalConnectionDate",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "VolumeQuantity",
                  "kind": "LinkedField",
                  "name": "thresholdValueForCalculatedPremptyAlert",
                  "plural": false,
                  "selections": (v3/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "DDAC",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "calcContainerTimeSeriesDatapoints",
      "args": [
        {
          "items": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "geniIdentity",
                  "variableName": "geniIdentity"
                },
                {
                  "kind": "Literal",
                  "name": "name",
                  "value": "sdcs:CALCULATED_CONTAINER_LEVEL"
                },
                {
                  "kind": "Literal",
                  "name": "raw",
                  "value": true
                }
              ],
              "kind": "ObjectValue",
              "name": "datapointSelectors.0"
            }
          ],
          "kind": "ListValue",
          "name": "datapointSelectors"
        },
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "startDate",
          "variableName": "startDate"
        }
      ],
      "concreteType": "TimeSeriesDatapoint",
      "kind": "LinkedField",
      "name": "timeSeriesDatapoints",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Datapoint",
          "kind": "LinkedField",
          "name": "latestDatapoint",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "valueRaw",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};
})();

(node as any).hash = "a8acb137b05f1e927359f8c6d10e81e8";

export default node;
