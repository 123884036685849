import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationBG from './assets/translations/bg.json';
import translationCS from './assets/translations/cs.json';
import translationDE from './assets/translations/de-DE.json';
import translationEL from './assets/translations/el.json';
import translationEN from './assets/translations/en.json';
import translationES from './assets/translations/es.json';
import translationFR from './assets/translations/fr-FR.json';
import translationHR from './assets/translations/hr.json';
import translationHU from './assets/translations/hu.json';
import translationIT from './assets/translations/it-IT.json';
import translationNL from './assets/translations/nl-NL.json';
import translationPL from './assets/translations/pol.json';
import translationPT from './assets/translations/pt.json';
import translationRO from './assets/translations/ro.json';
import translationSK from './assets/translations/sk.json';
import translationSL from './assets/translations/sl.json';
import translationSR from './assets/translations/sr.json';
import env from './env';

const resources = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
  nl: {
    translation: translationNL,
  },
  pl: {
    translation: translationPL,
  },
  bg: {
    translation: translationBG,
  },
  cs: {
    translation: translationCS,
  },
  el: {
    translation: translationEL,
  },
  hr: {
    translation: translationHR,
  },
  hu: {
    translation: translationHU,
  },
  ro: {
    translation: translationRO,
  },
  sk: {
    translation: translationSK,
  },
  sl: {
    translation: translationSL,
  },
  pt: {
    translation: translationPT,
  },
  sr: {
    translation: translationSR,
  },
};

i18n
  // For now we use the browser to decide which language to display.
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: { order: ['navigator'] },
    resources,
    fallbackLng: 'en',
    debug: env.GIC_UI_ENVIRONMENT !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
